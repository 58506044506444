.payment-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh; 
  }
  .container-wrapper {
    max-width: 725px;

  }
  .form-container {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .input-row {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
  }
  .input-row input[type="tel"] {
       max-width: 240px;

  }
  .input-row label {
    margin-right: 10px;
  }
  
  .input-row input[type="tel"] {
    width: 100%;
    padding: 8px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 3px;
  }
  
  .description {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .description label {
    font-size: 14px;
    color: #666;
  }
  
  .image-container {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  
  .swish-logo {
    width: 220px;
    height: 78px;
}
  
  .button-container {
    display: flex;
    justify-content: center;
  }
  
  .pay-button, .cancel-button {
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    border-radius: 50px; /* Rounded edges */
    cursor: pointer;
    margin: 0 10px;
  }
  
  .pay-button {
    background-color: green;
    color: white;
  }
  
  .cancel-button {
    background-color: red;
    color: white;
  }
  .tooltip {
    color: red;
    font-size: 12px;
    margin-top: 5px;
  }
  