@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Poppins", sans-serif!important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.sidebar-menu {
  background: #022627;
  width: 80px;
  padding: 20px 0px;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sidebar-menu ul {
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 50px;
}

header {
  display: flex;
  justify-content: center;
  left: 80px;
  padding: 10px 10px;
  position: fixed;
  width: calc(100% - 80px);
  top: 0;
  background: #fff;
  z-index: 99;
}

.navbar-order a {
  display: flex;
  background: #022627;
  padding: 15px 40px;
  font-size: 16px;
  border-radius: 30px;
  color: #fff;
  text-decoration: none;
  font-weight: 600;
  justify-content: center;
  align-items: center;
  transition: all 0.5s;
}

.navbar-order a:hover {
  background: #ff9800;
}


.sidebar-menu ul li a, .sidebar-menu ul li span {
  color: #fff;
  text-decoration: none;
  padding: 6px 13px;
  display: flex;
  border-left: 4px solid transparent;
  cursor: pointer;
}

.sidebar-menu ul li a img{
  width: 50px;
}

.sidebar-menu ul li span img {
  width: 45px;
}

.sidebar-menu ul li a.active {
  border-left: 4px solid #FF9800;
}

.navbar-header {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.navbar-ribbons {
  position: relative;
}

.navbar-ribbons img {
  max-width: 250px;
}


.main-content {
  margin-left: 80px;
  margin-top: 100px;
}

.page-content {
  padding: 20px;
}

.spinner-border {
  position: absolute;
  left: 50%;
}

.language-modal .modal-title {
  font-size: 18px;
  font-weight: 600;
}

.language-modal .modal-header {
  padding: 12px 15px;
}

.language select {
  width: 100%;
  border: 1px solid #dee2e6;
  padding: 10px 15px;
  border-radius: 8px;
}

.language ul {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.language-item {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
}

.language-item label {
  cursor: pointer;
}



@media screen and (max-width:991px){


.navbar-ribbons {
    display: none;
}

.navbar-header {
  justify-content: center;
}

.product-items {
  width: 100%;

}
}