.home {
    background: #022627;
}

.home-cover img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.home-cover {
    position: relative;
    height: calc(100vh - 217px);
    overflow: hidden;
}

.home-grid {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    padding: 40px 20px;
}

.home-link img {
    width: 50px;
}

.home-link a {
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    align-items: center;
    color: #fff;
    text-decoration: none;
}

.home-link a span {
    font-size: 20px;
}

.home-logo {
    max-width: 400px;
    width: 100%;
}

.home-logo img {
    width: 100%;
}


@media screen and (max-width:767px){

.home-grid {
    gap: 20px;
    padding: 20px 20px;
    flex-wrap: wrap;
}

.home-logo {
    order: 1;
}

.home-link {
    order: 2;
    width: calc(50% - 13px);
}

.home-cover {
    height: calc(100vh - 218px);
}

.home-logo {
    max-width: 200px;
    width: 100%;
    margin: 0px auto;
}

}