.product-page {
  position: relative;
  height: calc(100vh - 120px);
  overflow-y: auto;
}
.product-page h4 {
    font-size: 28px;
    font-weight: 600;
    margin: 0;
    padding-bottom: 20px;
  }
  
  .product-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }
  
  .product-items {
    width: calc(33.33% - 20px);
    position: relative;
    border-radius: 12px;
    overflow: hidden;
    cursor: pointer;
    border: 1px solid #efeaea;
  }
  
  .product-cover {
    position: relative;
  height: 100%;
    /* overflow: hidden; */
  }
  
  .product-cover img {
    width: 100%;
    object-fit: cover;
    height: 100%;
    transition: all 0.5s;
  }
  
  .product-items:hover .product-cover img {
  transform: scale(1.1);
  }
  
  .product-items:hover .product-title {
    background: #000000e6;
  
  }
  
  .product-items:hover .product-title .btn{
    transform: rotate(360deg);
  }
  .product-cover span {
    top: 10px;
    background: #F44336;
    position: absolute;
    right: 0;
    padding: 10px 10px 10px 50px;
    color: #fff;
    clip-path: polygon(25% 0%, 100% 0%, 100% 100%, 25% 100%, 50% 50%);
    font-size: 14px;
  }
  
  .product-title {
    position: absolute;
    width: 100%;
    background: #0000009c;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: all 0.5s;
  }
  
  .product-title p {
    color: #fff;
    margin: 0;
    font-size: 18px;
    font-weight: 600;
  }
  
  .product-title .btn, .product-title .btn:hover, .product-title .btn:focus, .product-title button:focus:not(:focus-visible) {
    background: #ff9800;
    border-color: #ff9800;
    font-size: 30px;
    padding: 0;
    line-height: 1;
    width: 40px;
    height: 40px;
    font-weight: 600;
    transition: all 0.5s;
  }
  /*********** Cart *************/

  .cart-page {
    position: relative;
}

.cart-page-header {
    display: flex;
    background: #022627;
    padding: 20px 20px;
    justify-content: space-between;
    align-items: center;
}

.cart-page-header-item {
    color: #fff;
    gap: 10px;
    display: flex;
    align-items: center;
    font-size: 18px;
}

.cart-page-header-item img {
    width: 20px;
}


.cart-page-header-item a {
    color: #fff;
    text-decoration: none;
    font-size: 12px;
}


.cart-page-order table.table {
    margin: 0;
}


.cart-page-order {
    padding: 50px 20px;
    text-align: center;
}



.cart-page-order table td, .cart-page-order table th {
    vertical-align: middle;
    border: none;
    padding: 5px 10px;
}


.cart-page-order .btn {
    margin: 0px 5px;
}


.cart-page-content {
    text-align: center;
}

.cart-table-code  h4 {
    font-size: 18px;
    margin: 0;
    padding-bottom: 20px;
}

.cart-table-code input {
    background: transparent;
    border: none;
    border-bottom: 2px solid #959191;
    width: 100%;
    text-align: center;
    color: #333;
    outline: none;
    width: 150px;
}

.cart-table-code {
    margin-bottom: 50px;
}

.cart-table-payment {
    display: flex;
    justify-content: space-between;
    max-width: 1200px;
    margin: 0px auto;
}

.cart-table-payment-items {
    width: 33.33%;
}

.cart-table-payment-items p {
    max-width: 350px;
    margin: 0px auto;
    margin-top: 10px;
    font-size: 12px;
}

.cart-table-payment-items .btn, .cart-table-payment-items .btn:hover, .cart-table-payment-items .btn:focus, .cart-table-payment-items button:focus:not(:focus-visible) {
    display: flex;
    background: #022627;
    padding: 12px 30px;
    font-size: 16px;
    border-radius: 30px;
    color: #fff;
    text-decoration: none;
    font-weight: 600;
    justify-content: center;
    align-items: center;
    transition: all 0.5s;
    border-color: #022627;
    margin: 0px auto;
}

.cart-table-payment-items .btn:hover{
background: #ff9800;
border-color: #ff9800;
}

@media screen and (max-width:1440px){
    .product-items {
      width: calc(33.33% - 15px);
  }
  }


@media screen and (max-width:991px){

    .product-items {
   
      width: calc(33.33% - 15px);
    }

    .cart-table-payment {
        flex-direction: column;
        gap: 20px;
    }
    
    .cart-table-payment-items p {
        font-size: 12px;
    }
    
    .cart-table-payment-items {
        width: 100%;
    }

    .cart-page-order table td, .cart-page-order table th {
        white-space: nowrap;
        padding: 5px 20px;
    }

    .cart-page-order {
        padding: 10px 10px;
        text-align: left;
    }

    .cart-page-header-item, .cart-table-payment-items .btn, .cart-table-payment-items .btn:hover, .cart-table-payment-items .btn:focus, .cart-table-payment-items button:focus:not(:focus-visible) {
   
        font-size: 14px;
    }

    .cart-page-header .cart-page-header-item:last-child {
        display: none;
    }

    .cart-page-header-item img {
        width: 15px;
    }

    .shopping-cart {
      max-width: 100px !important;
      left: 50% !important;
    }

}


@media screen and (max-width:667px){

    .product-items {
      width: 100%;
    
    }
}
.modal-footer-btn{
    background-color: orange !important;
}
.modal-footer-btn:hover{
    background-color: #cd8a0e !important;
    color: white !important;
}
.shopping-cart {
  max-width: 238px;
  height: 54px;
  width: 100%;
  background:transparent;
  position: fixed;
  top: 65px;
  left: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 30px;
  /* transform: translateX(-50%); */
}
  .item-list {
    display: grid;
    align-items: center;
    grid-template-columns: repeat(5, minmax(0, 1fr));
    grid-gap: 28px;
    gap: 28px;
    margin-top: 150px;
  }
  
  .item {
    margin: 20px;
    display: flex;
    text-align: center;
    flex-direction: column;
  }
  
  .shake {
    animation: shake 0.2s ease-in-out;
  }
  
  @keyframes shake {
    0%,
    100% {
      transform: translateX(0);
    }
    50% {
      transform: translateX(-5px);
    }
  }