body {
    margin: 0;
    font-family: Arial, sans-serif;
  }
  
  .app-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100vh;
    text-align: center;
    padding: 20px;
  }
  
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    background-color: #800000;
    color: white;
    padding: 10px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
  }
  .back-button {
    background: none;
    border: none;
    color: white;
    font-size: 20px;
    cursor: pointer;
  }
  .logo {
    height: 40px;
  }
  .logo-net {
    height: 50px;
  }
  
  .question-content {
    margin-top: 120px;
    padding: 0 20px;
    margin-bottom: 20px;
  }
  

  



  
 
  
  
  