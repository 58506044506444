body {
    margin: 0;
    font-family: Arial, sans-serif;
  }
  
  .app-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100vh;
    text-align: center;
    padding: 20px;
  }
  
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    background-color: #800000;
    color: white;
    padding: 10px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
  }
  
  .back-button {
    background: none;
    border: none;
    color: white;
    font-size: 20px;
    cursor: pointer;
  }
  
  .logo {
    height: 40px;
  }
  
  .content {
    margin-top: 60px; 
    margin-bottom: 20px;
    padding: 0 20px;
  }
  .question-content {
    margin-top: 60px;
    margin-bottom: 20px;
    padding: 0 20px;
  }
  
  .button-container {
    display: flex;
    justify-content: center;
    gap: 20px;
  }
  
  .yes-button, .no-button {
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .yes-button {
    background-color: green;
    color: white;
  }
  
  .no-button {
    background-color: red;
    color: white;
  }

  .answer-buttons {
    display:grid;
    flex-direction: row;
    gap: 10px;
    justify-content: center; 
    grid-template-columns:auto auto

  }
  
  .answer-button {
    padding: 10px 40px;
    margin: 5px; 
    color: black;
    border-radius: 4px;
    cursor: pointer;
    border: 1px solid #000;
   
  }
  
  .answer-button:hover {
    background-color: #0056b3;
    color: #fff;
  }
  
  
  