  .product-info h2 {
    color: orange;
    font-size: 24px;
    font-weight: 700;
    border-bottom: 1px solid orange;
    padding-bottom: 15px;
    margin-bottom: 15px;
}
.product-info {
    text-align: left;
}

.modal-grid {
    display: flex;
    align-items: center;
    gap: 0px;
    padding: 30px;
    padding-right: 0;
    padding-top: 10px;
}

.popup_image img {
    width: 100%;
}

.description h3 {
    font-size: 20px;
    font-weight: 600;
}

.description p {
    font-size: 14px;
}

button.add-button {
    background: orange;
    border: 1px solid orange;
    color: #fff;
    font-size: 14px;
    padding: 10px 20px;
    border-radius: 30px;
    width: 120px;
    margin-top: 75px;
}

.popup_image {
  position: relative;
  max-width: 400px;
}

.popup_image img {
  width: 100%;
  margin-left: 100px;
}

.modal-grid  .description {
  text-align: left;
  margin-bottom: 20px;
}

.modal-product .modal-header {
  border: none;
  padding-bottom: 0;
}

/* good choice css */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 300px;
  max-width: 100%;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  color: #FFFF;
}

.modal-footer {
  display: flex;
  justify-content: flex-end;
}

.modal-footer button {
  padding: 8px 16px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.modal-footer button:hover {
  background-color: #0056b3;
}
